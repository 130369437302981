html,
body {
    height: 100%;
    margin: 0px;
}
body > iframe{
    display: none !important;
    
}
.ytp-chrome-controls {
    display: none !important;
    visibility: hidden;
    opacity: 0;
}

::-webkit-scrollbar {
    background-color: #fff;
    width: 3px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: #fff
}

::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #7d7d7d5c;
    border-radius: 16px;
    border: 6px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none
}

hr {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}

div,
p,
img {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}


.monaco-editor * div {
    -webkit-touch-callout: all !important;
    /* iOS Safari */
    -webkit-user-select: all !important;
    /* Safari */
    -khtml-user-select: all !important;
    /* Konqueror HTML */
    -moz-user-select: all !important;
    /* Old versions of Firefox */
    -ms-user-select: all !important;
    /* Internet Explorer/Edge */
    user-select: all !important;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}

#root {
    height: 100%;
}

#canvas .row {
    height: 100%;

}

.mainCol {
    display: grid;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    padding: 10px;
}

@media screen and (max-width: 992px) {
    #canvas {
        height: calc(100vh - 212px);
        margin-bottom: 15px;
    }

    .footer {
        padding: 5px 0;
    }
}

@media screen and (min-width: 992px) {
    #canvas {
        height: calc(100vh - 180px);
        margin-bottom: 15px;
    }

    .footer {
        padding: 5px 0;
    }
}

.custom-header {
    padding-bottom: 5px !important;
    padding-top: 10px !important;
}

.sweet-alert {
    top: 30%;
}

.sortable-ghost {
    /*  border-width: 2px;
    border-style: dashed;
    border-radius: 4px;
    border-color: #2baebfa6;*/



}


/*.sortable-drag {
    background: #fff;
    border: 1px solid rgb(194, 194, 194);
    border-radius: 6px;

}*/

.sortable-ghost::after {
    content: '';
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}


.sortable-chosen.sortable-ghost {
    visibility: hidden;
    /*
    opacity: 0;
    */
    height: 1px;
    position: relative;

}

/*
.sortable-fallback {
    opacity: 1 !important;
    visibility: visible;

}*/

.device-loading {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #717171;
    flex-direction: column;
}

.device-loading::before {
    position: absolute;
    bottom: 0;
    top: 0;
    background: #b3dfe596;
    content: '';
    width: 100%;
    pointer-events: fill;
    z-index: 9999;
    border-radius: 35px;
}


.device-notworking {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #717171;
    flex-direction: column;
}

.device-busy {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: transparent;
    flex-direction: column;
}

.device-busy::before {
    position: absolute;
    bottom: 0;
    top: 0;
    background: transparent;
    content: '';
    width: 100%;
    pointer-events: fill;
    z-index: 9999;
    border-radius: 35px;
}

.device-notworking::before {
    position: absolute;
    bottom: 0;
    top: 0;
    background: #c1c1c11f;
    content: '';
    width: 100%;
    pointer-events: fill;
    z-index: 9999;
    border-radius: 35px;
}

.device {
    -webkit-transform-origin: center top 0px;
    transform-origin: center top 0px;
    width: 407px;
    height: 787px;
    padding: 60px 16px;
    background: rgb(255, 255, 255);
    border-radius: 35px;
    box-shadow: #dddddda1 0px 0px 30px 0px;
    position: relative;
}

.device-ui {
    transform-origin: center top 0px;
    width: 407px;
    height: 787px;
    padding: 60px 16px;
    position: relative;
}

.deviceInside {
    height: 100%;
    background: rgba(226, 226, 226, 0.56);
    border: 1px solid rgb(224, 224, 224);
    max-height: 670px;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0px 1px;
    position:relative;
}

.uiInside {
    /* background: transparent !important;*/
}


#emulatorContainer {
    padding: 12px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0 8px 16px rgba(50, 50, 50, 0.3), inset -2px -4px 12px rgba(50, 50, 50, 0.2);
    position: relative;
    width: 321px;
    height: 640px;
}

.dynamicComponent {
    position: relative;
    display: flex;
    z-index: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
}

.dynamicSubComponent {
    position: relative;
    display: block;
    z-index: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
}

.dynamicSubComponent::after {
    content: "";
    display: table;
    clear: both;
}

.dragableComponent {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.dragableComponent:active,
.dragableComponent:focus>.dragableComponentMore {
    display: block;
}

.dragableComponentHelper {
    display: none;
}

.dragableComponent:active,
.dragableComponent:focus>.dragableComponentHelper {
    display: block;
}

.dragableComponentMore {
    /*  
    display: none;
     outline: #2baebf solid 2px;
   */
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;


}



/*
.dragableComponent:hover>.dragableComponentMore {
    display: block;
}
*/



.dragableComponentActions {
    /*  
    display: none;
   */

    position: absolute;
    font-size: 10px;
    z-index: 2;
    justify-content: space-evenly;
    flex-direction: row;
    top: 0;
}


.dragableComponent:active,
.dragableComponent:focus>.dragableComponentMore {
    display: block;
}

.sortable-ghost>.dragableComponent>.dragableComponentMore {
    display: none;
}

.sortable-chosen>.dragableComponent>.dragableComponentMore {
    display: none;
}


.dragableComponentButton {}

.dragableComponentButton:hover>.dragableComponentMore {
    display: block;
}

/*Sidebar*/


.componentItem {
    margin-left: 9px;
    background-color: rgb(255, 255, 255);
    text-transform: uppercase;
    min-width: 95px;
    max-width: 95px;
    height: 95px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 13px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px;
    cursor: -webkit-grab;
    transition-duration: 0.15s;
    transition-property: box-shadow;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    /* flex: 1 1 0%;*/
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221, 221, 221);
    border-image: initial;
    border-radius: 4px;
}

.componentItemMini {}

.componentItemMini>.componentItemIcon {
    color: rgba(85, 123, 128, 0.7);
}


.componentItemMini>.componentItemText {
    display: none;
}

.componentItem:hover {
    box-shadow: rgba(0, 0, 0, 0.247) 1px 6px 10px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221, 221, 221);
    border-image: initial;
}



.componentItemContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    /*justify-content: space-between;*/
    align-content: flex-start;
    padding: .2px;
}

.componentItemContainerMini {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 10px;
}

.componentItemText {
    color: rgb(126, 126, 126);
    font-weight: 400;
    overflow-wrap: break-word;
    text-overflow: ellipsis;

    overflow: hidden;
    padding: 0px 6px;
}

.componentItemIcon {
    color: rgb(126, 126, 126);
    margin-bottom: 9px;
    font-size: 20px;
}

/* HELPER MENU*/

.contextMenu {
    position: fixed;
    z-index: 3;
    background: white;
    box-shadow: 0px 2px 10px #999999;
}

.contextMenu--option {
    padding: 6px 50px 5px 10px;
    min-width: 160px;
    cursor: default;
    font-size: 12px;
}





.contextMenu--option__disabled {
    color: #999999;
    pointer-events: none;
}

.contextMenu--separator {
    width: 100%;
    height: 1px;
    background: #CCCCCC;
    margin: 0 0 0 0;
}


.centerSide {
    display: flex;
}

.centerSideMain {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rigthMenuSide {
    height: auto;
    background-color: #f8f9fe;
    border-color: rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    display: flex;
    justify-content: flex-end;
}

.rigthMenuSideInside {
    width: 300px;
    transition: width .5s;
    position: relative;
    overflow: auto;
}

.rigthMenu-container {
    position: relative;
    overflow: auto;
    height: calc(100vh - 50px);
    overflow-x: hidden;
}

.rigthMenuSidePasive {
    width: 0px !important;
}


.rigthMenuSideTabs {
    /* box-shadow: 0px 3px 6px #00000029;*/
    width: 63px;
    background-color: #ffffff;
    border-left: 1px solid #fbfbfb;
}

.rigthMenuTab {
    padding-right: unset !important;
    cursor: pointer;
    margin-bottom: 0px !important;
}

.rigthMenuTab>a {
    margin-bottom: 0px !important;
}

.rigthMenuTabInside {
    border-radius: unset !important;
}

.nav-wrapper2 {}


.rigth-tab-shadow {
    /* margin-right: 1px;*/
    margin-bottom: 0px !important;
    min-height: calc(100vh - 50px);
}

.rigth-tab-pane {
    min-height: calc(100vh - 125px);

}



.component-hover {
    padding: 5px;
    transition: padding 1s;
}


.component-hover::after {
    content: " ";
    border: 2px double #04ffde;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    display: block;
}



.property-dropdown-div {
    display: flex;
}

.property-dropdown-input {
    flex: 2 1 !important;
    transition: flex .5s;

}

.property-dropdown-input-display {
    flex: 0 0 !important;
    transition: flex .5s;
    padding: 0;
    border: none;
}

.property-dropdown-input-display-none {
    display: none !important;
}

.property-dropdown {
    flex: 1 1;
    transition: flex .5s;
}

.property-dropdown2 {
    border: 1px solid #2baebf;
    color: #2baebf;
}

.property-dropdown2:hover {
    box-shadow: unset;
    transform: none;
    border: 1px solid #2baebfcf;
    color: #2baebfcf;

}

.property-dropdown>.btn-group {
    flex: 1 1;
    transition: flex .5s;

}

.property-dropdown-div>.property-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px !important;
}

.property-dropdown-div>.property-dropdown::before {
    content: "";
    display: inline-block;
}

.property-dropdown>.btn-group>.property-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px !important;
}

.property-dropdown>.btn-group>.property-dropdown::before {
    content: "";
    display: inline-block;
}

.property-dropdown-noType::after {
    border: 0;
}


/* Sidebar Start */
.g-sidenav-hidden>#root>.sidenav:hover {
    max-width: 70px !important;
    width: 70px !important;
}

.sidenav {
    overflow-x: hidden;
}

@media screen and (max-width: 600px) {
    .g-sidenav-pinned .sidenav {
        max-width: 240px !important;
    }

    .g-sidenav-hidden>#root>.sidenav:hover {
        width: 100% !important;
        max-width: 62px !important;
    }

    .componentItemContainerMini {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
        align-content: flex-start;
    }

    .componentItemMini {
        background-color: rgb(255, 255, 255);
        text-transform: uppercase;
        min-width: 95px;
        max-width: 95px;
        height: 95px;
        line-height: 20px;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 13px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px;
        cursor: -webkit-grab;
        transition-duration: 0.15s;
        transition-property: box-shadow;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        flex: 1 1 0%;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(221, 221, 221);
        border-image: initial;
        border-radius: 4px;
    }

    .componentItemMini>.componentItemIcon {
        color: rgb(126, 126, 126);
        margin-bottom: 9px;

    }

    .componentItemMini>.componentItemText {
        display: block;
    }

}

.sidenav-toggler {
    display: block !important;
}

.hamburgerMenu-Header {
    justify-content: space-between;
    padding-right: 10px;
}

.navbar-brand-img {
    font-family: 'Open Sans', sans-serif;
}


.category-item {
    background: #f6f9fc;
    border-bottom: 1px solid #e4e8ec;
}



.category-icon-null {
    color: "white";
    background: #d2d2d2;
    text-align: center;
    border-radius: 6px;
    padding: 4px 0px;
}

.category-name {
    margin: auto;
    margin-left: 20px;
}

.nav-link2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    transition: padding .5s;
}

.nav-link2-active {
    padding: 0.675rem 1.3rem !important;
}





/* Sidebar End */




.noUi-tooltip {
    display: none;
}

.noUi-active .noUi-tooltip {
    display: block;
}

.noUi-horizontal {
    position: relative;
    margin-right: 20px;
    margin-left: 12px;
}

.noUi-tooltip {
    border-radius: 7px;
    padding: 5px;
    font-size: 11px;
}

.propertyTitleDiv {
    display: flex;
    justify-content: space-between;
}

.propertyTitle {
    display: flex;
    font-size: 14px;
}

.propertySubTitle {
    font-size: 14px;
    margin: 0;
    margin-left: 6px;
}

.propertyFontStyleToggle {
    box-shadow: none !important;
}

.sketch-picker {
    box-shadow: unset !important;
}

.color-picker-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: #fff;
    border-top: 1px solid #eee;
}

.property-form-group {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 10px;
}

.property-category-card {
    box-shadow: none !important;
    margin-bottom: 0px;
    overflow: unset !important;
}



.property-category {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0;
}

.property-category-title {
    color: #525f7f;
    font-size: 16px;
}

.property-category::after {
    right: 0.8rem !important;
    color: #cdd0d9;

}

.property-category-body {
    padding: 0.3rem;
    padding-top: 1.5rem;
}

.popover-colorpicker {
    background-color: transparent !important;
}

.popover-colorpicker>div {
    background-color: transparent !important;
}

.popover-colorpicker-body {
    padding: 0px
}

.layout-treeview {
    cursor: pointer;
    border: 1px solid #b4e3e9;
    border-radius: 6px;
    padding: 5px 10px;
    color: #4ab2bf;
    text-indent: 0em;
}

.layout-treeview:hover {
    background-color: #b4e3e9;
    border-radius: 6px;
    padding: 5px 10px;
    color: #fff;
    text-indent: 0em;
}



.layout-treeview-caret {
    cursor: pointer;
    list-style: none;
    text-indent: -1em;
    -webkit-user-select: none;
    /* Safari 3.1+ */
    -moz-user-select: none;
    /* Firefox 2+ */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    position: relative;
}

.layout-treeview-caret::before {
    content: "\2295";
    color: #b4e3e9;
    display: inline-block;
    margin-right: 6px;
    position: absolute;
    top: 1px;
    font-size: 24px;
    margin-left: 5px;
}

.layout-treeview-ul {
    list-style-type: none;
    padding-inline-start: 15px;
}

/*
.modal-open>#root {
    filter: blur(3px)
}
*/

#root {
    /*  -webkit-transition: filter 500ms ease-out 1s;
    -moz-transition: filter 500ms ease-out 1s;
    -o-transition: filter 500ms ease-out 1s;
    transition: filter 500ms ease-out 1s;*/
}

.property-icon {
    width: 45px;
    height: 45px;
    background-color: #34b5c640;
    border: 2px double #7fcfda;
    border-style: dashed;
    border-radius: 6px;
    background-size: cover;
    position: relative;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.property-image {
    width: 45px;
    height: 45px;
    background-color: #34b5c640;
    border: 2px double #7fcfda;
    border-style: dashed;
    border-radius: 6px;
    background-size: cover;
    position: relative;
    margin-right: 5px;
}

.property-image-remove {
    width: 20px;
    height: 20px;
    background-color: #34b5c6;
    border-radius: 30px;
    position: absolute;
    right: -5px;
    top: -10px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #fff;
}

.property-image-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.icons-modal-box {
    padding: 10px;
    cursor: pointer;
    width: 90px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #dadada;
    border-radius: 4px;
    color: #a09e9e;
    margin: 5px;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.icons-modal-box-icon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons-modal-box-span {
    width: 70px;
    height: 23px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

.icons-modal-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

}

.gallery-modal-container {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: fit-content;
    margin-left: 2%;

}

.gallery-modal-container2 {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: fit-content;
    justify-content: center;

}

.dz-button {
    background: transparent !important;
}

.gallery-image-modal-box {
    padding: 0px;
    cursor: pointer;
    /* width: 134px;*/
    min-height: 86px;
    max-height: 86px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #dadada;
    border-radius: 4px;
    color: #a09e9e;
    margin: 5px;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    position: relative;
}

.gallery-image-modal-box-controls::before {
    content: "";
    background: url(https://mobilex360-storage.s3.eu-central-1.amazonaws.com/storage/7db03473-9119-487e-81af-7aa5d4b687e9128x126.png);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.gallery-image-modal-box-img {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.gallery-image-modal-box-controls {
    width: 100%;
    height: 100%;
    position: relative;
    background: #0AF29B;

}

.gallery-image-modal-box-actions {
    display: flex;
    transition: height .1s;
    pointer-events: auto;
    justify-content: space-around;
    align-items: center;
    -webkit-animation: slide-out-bottom .5s cubic-bezier(.55, .085, .68, .53) both;
    animation: slide-out-bottom .5s cubic-bezier(.55, .085, .68, .53) both
}

.gallery-image-modal-box:hover>.gallery-image-modal-box-actions {
    -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}



.gallery-image-modal-box:hover>.gallery-image-modal-box-controls::after {
    content: "";
    position: absolute;
    background-color: #79b2ba96;
    width: 100%;
    height: 100%;
    top: 0;

    -webkit-animation: slide-in-fwd-center .4s cubic-bezier(.25, .46, .45, .94) both;
    animation: slide-in-fwd-center .4s cubic-bezier(.25, .46, .45, .94) both
}



@-webkit-keyframes slide-in-blurred-bottom {
    0% {
        -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(.2);
        transform: translateY(1000px) scaleY(2.5) scaleX(.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
        height: 0;
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
        height: 40px;
    }
}

@keyframes slide-in-blurred-bottom {
    0% {
        -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(.2);
        transform: translateY(1000px) scaleY(2.5) scaleX(.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
        height: 0;
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
        height: 40px;
    }
}

@-webkit-keyframes slide-out-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        height: 40px;
    }

    100% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
        height: 0;
    }
}

@keyframes slide-out-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        height: 40px;
    }

    100% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
        height: 0;
    }
}

.button-group-icons {
    width: 14px;
    height: 14px;
}

.button-group-button {
    box-shadow: none !important;
    padding: 10px 15px !important;
}

.element-icons {
    vertical-align: text-bottom;
    margin: 0px 5px;
}

.element-icons>span {
    vertical-align: super;
}

.card-custom {
    margin: 0px !important;
    ;
    box-shadow: unset !important;
    -webkit-box-shadow: unset !important;
}

.puff-out-center {
    -webkit-animation: puff-out-center 1s cubic-bezier(.165, .84, .44, 1.000) both;
    animation: puff-out-center 1s cubic-bezier(.165, .84, .44, 1.000) both
}

@-webkit-keyframes puff-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0
    }
}

@keyframes puff-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0
    }
}


.global-property-content {
    padding: 10px 0px;
}

.global-props-text-preview-container {
    border: 2px dashed #8898aa;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-button-container-text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-button-container-loading {
    position: absolute;
}

.global-props-container {}

.global-props-detail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.global-props-detail-hr {
    width: 1px;
    height: 50px;
    background: #2baebf;
    display: flex;
    margin: 0px 30px;
}

.global-props-detail-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    color: #2baebf;
}

.global-props-list-item-h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.global-props-list-item-container {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}

.select-font-size>span>span>span>span {
    font-size: 0.875rem !important;

}

.selection::after {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    font: normal normal normal 14px/1 NucleoIcons;
    line-height: 0;
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    content: "\ea0f";
    right: 0.8rem !important;
    color: #cdd0d9;
    pointer-events: none;

}

.select2-container--open>.selection::after {
    transform: rotate(180deg);
}

/*
.dropdown-toggle::after {
     border-right: unset;
    border-top: unset;
    border-bottom: unset;
    border-left: unset;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    font: normal normal normal 14px/1 NucleoIcons;
    line-height: 0;
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    content: "\ea0f";
    right: 0.8rem !important;
    pointer-events: none;
}
*/
.show>.dropdown-toggle::after {
    transform: rotate(180deg);
}



.color-picker-globalcolors {
    border-top: 1px solid rgb(238, 238, 238);
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 10px 10px;
    background: white;
    width: 228px;
}

.color-picker-globalcolors-item {
    width: 16px;
    height: 16px;
    margin: 0px 10px 10px 0px;
}

.color-picker-globalcolor-item-div {
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: relative;
    outline: none;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}

.dropdown-global-fontsize {
    font-size: 14px;
    margin: 0;
    margin-left: 6px;
    padding: 0 5px;
    font-weight: 300;
    color: rgb(103, 114, 141);

    justify-content: center;
    align-items: center;
    display: flex;
}

.dropdown-global-fontsize-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    max-width: 80px;
}

.modal-sweet-alert {}

.custom-popover-gif {
    width: 300px;
}

.custom-popover-content {
    width: 300px;
    padding: 8px;
    color: #7F7F7F;
}

.custom-popover-content>h5 {
    color: #7F7F7F !important;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4.5px;
}

.custom-popover-content>span {
    color: #787878 !important;
    font-size: 12px;
    line-height: 1;
}

.custom-popover-more {
    color: #2baebf !important;
    font-size: 12px;
    line-height: 1;
    display: flex;
    justify-content: flex-end;
}

.custom-popover-more>a {
    margin-right: 8px;
    cursor: pointer;
}

.custom-popover-more>a:hover {
    text-decoration: underline;
}

.dropzone {
    width: 90%;
}

.dz-message:hover {
    border: 2px dashed #11C5D9;
    color: #11C5D9;
}

.dz-message {
    border: 2px dashed #dee2e6;
    color: #11C5D9;
}

.dz-message {
    padding: 2rem 1rem;
}

.dz-message:hover>.dz-button {
    color: #11C5D9;
}

.dz-max-files-reached>.dz-message>.dz-button {
    background: transparent;
    color: #fff;

}

.dz-max-files-reached>.dz-message {
    background: transparent;
    color: #fff;

}

.dz-max-files-reached>.dz-message:hover {
    border: 2px dashed #dee2e6;
    color: #dee2e6;
}

.dz-max-files-reached>.dz-message {
    border: 2px dashed #dee2e6;
    color: #dee2e6;
}

.gallery-modal-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
}

.dropzone-single.dz-max-files-reached .dz-message {
    background-color: rgba(36, 151, 165, 0.55);
}

.slide-out {
    display: none;
}

.slide-in {
    display: block;
}

.cropper-tools-button {
    padding-left: 12px !important;
}

.cropper-tool-icon-rotate90 {
    -ms-transform: rotate(90deg);
    /* IE 9 */
    transform: rotate(90deg);
}

.cropper-tools-container {
    margin-top: 13px;
}

.cropper-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rs-btn-toolbar> :not(:first-child):not(.rs-btn-block) {
    margin-left: 5px;
    border-left: 1px solid #2baebf26 !important;
    border-radius: 0px;
}

.modal-footer-p0 {
    padding: 0;
}

.modal-header-pb0 {
    padding-bottom: 0px;
}


.modal-header-flex {
    display: flex;
    align-items: center;
}

.modal-loading-container {
    background-color: #79b2ba96;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 6px;
    -webkit-animation: slide-in-fwd-center .4s cubic-bezier(.25, .46, .45, .94) both;
    animation: slide-in-fwd-center .4s cubic-bezier(.25, .46, .45, .94) both
}

.custom-fade-in {
    animation: fadeIn .5s ease-in
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;

    }
}

@-webkit-keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

@keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1
    }
}

.notfound-stream-container {
    min-height: 96px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #8a98aa;
    font-size: 14px;
    font-style: italic;
}

.galleryTitleDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.galleryTitle {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #2baebf;
    background: transparent;
}


.pageBusy-container {
    padding: 0px 40px;
}

.pageBusy-container-row {
    display: flex;
    align-content: center;
    padding: 5px 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pageBusy-container-row>.rs-avatar {
    margin-bottom: 5px;
}

.pageBusy-title {
    color: #32325D;
    font-size: 24px;
    margin: 0px;
}

.pageBusy-header {
    padding-bottom: 5px !important;
}

.pageBusy-footer {
    justify-content: space-evenly !important;
}

.pageBusy-photo {
    border: 2px solid #0AF29B;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background-image: url("https://mobilex360-storage.s3.eu-central-1.amazonaws.com/storage/80147647-744d-4e6e-a19e-66addf3c820b600x646.png");
    background-position: center;
    background-size: contain;
}

.pageBusy-message {
    text-align: center;
    letter-spacing: 0px;
    color: #525F7F;
    font-size: 14px;
}

.gallery-crop-desc-row {
    width: 100%;
    margin: 10px;
}

.gallery-crop-desc-title {

    font-weight: 500;
}

.slide-in>div:last-child>div:last-child {
    border-radius: 0.4375rem !important;
    overflow: hidden !important;
    left: 0px !important;
    right: 0px !important;
    top: 20px !important;
    bottom: 20px !important;
}

.navbar-custom {
    padding: 1px;
}

.project-container {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.project-container1 {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}


.project-col {
    border-bottom: 1px solid #70707014;

}

.project-title {
    font-size: 0.85rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.project-title>a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    color: #8F8F8F;
}

.project-null-img {
    width: 80px;
    height: 80px;
    border: 4px dashed #2baebf;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 35px;
    font-weight: 100;
    color: #2baebf;
}

.project-img {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #9aa7b76b;

}

.project-null-img:hover {
    cursor: pointer;
}

.project-img:hover {
    cursor: pointer;
}


.eproject-img {
    cursor: auto !important;
}


.project-container:hover>.project-img {
    -webkit-animation: fade-in 1.2s cubic-bezier(.39, .575, .565, 1.000) both;
    animation: fade-in 1.2s cubic-bezier(.39, .575, .565, 1.000) both
}

.project-container:hover>.project-null-img {
    -webkit-animation: fade-in 1.2s cubic-bezier(.39, .575, .565, 1.000) both;
    animation: fade-in 1.2s cubic-bezier(.39, .575, .565, 1.000) both
}

.project-container:active>.project-img:hover {
    -webkit-animation: heartbeat 1.5s ease-in-out both;
    animation: heartbeat 1.5s ease-in-out both
}

.project-container:active>.project-null-img:hover {
    -webkit-animation: heartbeat 1.5s ease-in-out both;
    animation: heartbeat 1.5s ease-in-out both
}

@-webkit-keyframes fade-in {
    0% {
        opacity: .50
    }

    100% {
        opacity: 1
    }
}

@keyframes fade-in {
    0% {
        opacity: .50
    }

    100% {
        opacity: 1
    }
}



@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    10% {
        -webkit-transform: scale(.91);
        transform: scale(.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    17% {
        -webkit-transform: scale(.98);
        transform: scale(.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    33% {
        -webkit-transform: scale(.87);
        transform: scale(.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    10% {
        -webkit-transform: scale(.91);
        transform: scale(.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    17% {
        -webkit-transform: scale(.98);
        transform: scale(.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }

    33% {
        -webkit-transform: scale(.87);
        transform: scale(.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

.notPage {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em;
    text-align: center;
    color: #c7c7c7;
}

.notPage>i {
    margin-bottom: 10px;
    cursor: pointer;
}

.notPage>i:hover {
    -webkit-animation: heartbeat 1.5s ease-in-out both;
    animation: heartbeat 1.5s ease-in-out both
}

/*getPagesThumb*/

.ui-heigth-text {
    position: absolute;
    width: 90vh;
    transform: rotate(-90deg);
    bottom: 50%;
    text-align: center;
    right: 30px;
    display: flex;
    justify-content: center;
}

.ui-heigth-text>p {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background: #f8f9fe;
    z-index: 1;
    padding: 0 .5em;
}

.ui-width-text>p {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background: #f8f9fe;
    z-index: 1;
    padding: 0 .5em;
}


.ui-heigth-text::before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    top: 31%;
    border: 1px dashed #7e7f80a1;
}

.ui-width-text {
    text-align: center;
    position: absolute;
    top: 16px;
    width: 90vh;
    left: -50%;
    display: flex;
    justify-content: center;
}

.ui-width-text::before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    top: 14px;
    border: 1px dashed #7e7f80a1;
}


.custom-tree {
    border-radius: 4px;
    padding: 3px;
}

.custom-tree-selected {
    background: #d6edf094;
    border-radius: 4px;
    padding: 3px;
}

.tree-layout {
    min-height: 10px
}

.custom-tree-canvas .card-header:after {
    z-index: 2;
}

.custom-tree-canvas {
    /* margin-left: 30px;*/
}

.custom-tree-canvas * .dynamicComponent {
    padding: 0px 0px 0px 15px;
}

.custom-tree-canvas * .dynamicComponent>.dragableComponent {
    margin-left: 15px;
    width: calc(100% - 15px);
}




.component-hover::after {
    content: " ";
    border: 2px double #04ffde;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    display: block;
}

.custom-tree-collapse-button {
    position: absolute;
    left: 0;
}

.compenent-selector * .rs-picker-select-menu-item {
    padding: 8px 12px !important;
}

.compenent-selector * .rs-picker-select-menu-items {
    max-height: 70vh !important;
}

.component-selector-modal {
    max-width: 433px !important;
    margin: auto;
    top: 50px;
}

.component-selector-list {
    width: 100%;
}

.component-selector-list>.rs-list-item {
    padding: 13px;
    cursor: pointer;
}

.input-group-httpobject>.btn {
    height: 100%;
}

.input-group-httpobject>.dropdown {
    height: 100%;
}

.input-group-httpobject>.dropdown>.btn {
    height: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-httpobject-loader>.rs-loader {
    height: 14px;
    margin-left: 3px;
}

.icon-search {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.httpobject-modal {}

@media (max-width: 992px) {
    .httpobject-modal {
        margin: 1.75rem auto;
        padding: 0.75rem;
        max-width: none;
    }
}

.monaco-wrapper {
    -webkit-touch-callout: auto;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
}

.monaco-wrapper * {
    -webkit-touch-callout: auto;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
}

.react-bootstrap-table {
    width: 100%;
    margin-bottom: 0px !important;
}

.react-bootstrap-table * th {
    width: 50%;
}

.react-bootstrap-table-editing-cell {
    padding: 10px !important;
    padding-left: 7px !important;

}

.react-bootstrap-table-editing-cell>input {
    font-size: 13px !important;
}

.rs-input-sm {
    padding: 4px 11px !important;
    height: auto;
}

.enviroment-new-add {
    color: #c3c4c4;
    font-size: 12px;
    font-weight: 100;
}

.httpObject-Body {
    width: 100%;
    /*height: 50vh;*/
    position: relative;
}

/*  */

.splitter-layout {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.splitter-layout .layout-pane {
    position: relative;
    flex: 0 0 auto;
    overflow: auto;
}

.splitter-layout .layout-pane.layout-pane-primary {
    flex: 1 1 auto;
}

.splitter-layout>.layout-splitter {
    flex: 0 0 auto;
    width: 4px;
    height: 100%;
    cursor: col-resize;
    background-color: #ccc;
}

.splitter-layout .layout-splitter:hover {
    background-color: #bbb;
}

.splitter-layout.layout-changing {
    cursor: col-resize;
}

.splitter-layout.layout-changing>.layout-splitter {
    background-color: #aaa;
}

.splitter-layout.splitter-layout-vertical {
    flex-direction: column;
}

.splitter-layout.splitter-layout-vertical.layout-changing {
    cursor: row-resize;
}

.splitter-layout.splitter-layout-vertical>.layout-splitter {
    width: 100%;
    height: 4px;
    cursor: row-resize;
}

.modal-card {
    box-shadow: none !important;
    margin-bottom: 0px;
}

.modal-card>.card-header {
    /*  padding: 0px;*/
}


.accordion-httpobject {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.accordion-httpobject2 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
}

.accordion-httpobject *.card-body {
    padding: 0px !important;
}

.accordion-httpobject2 *.card-body {
    padding: 0px !important;
    padding-top: 1.5rem !important;
}

.httpobject-modal * .modal-header {
    padding-bottom: 0px;
}

.clickable {
    cursor: pointer;
}

.httpobject-status {
    display: flex !important;
    flex-direction: row;
    font-weight: 100;
    font-size: 13px;
    padding: 10px 0px;
    justify-content: flex-end;
}

.httpobject-status-part {
    margin-right: 10px;
    color: #909090;

}

.warring-color {
    color: #F4B400;
    margin-left: 5px;
}

.success-color {
    color: #4caf50;
    margin-left: 5px;
}

.fail-color {
    color: #f44336;
    margin-left: 5px;
}

.httpobject-status-popover {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 13px;
    padding-bottom: 10px;
}

.httpobject-status-popover>span {
    display: flex;
    flex-direction: row;
    font-weight: 100;
    font-size: 13px;
    margin-left: 5px;
}

.httpobject-response {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #c5c5c5;
    padding-top: 3rem;
    align-items: center;
}

.httpobject-response>i {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #2baebf2e;
}

.tilt-in-fwd-bl {
    -webkit-animation: tilt-in-fwd-bl .6s cubic-bezier(.25, .46, .45, .94) both;
    animation: tilt-in-fwd-bl .6s cubic-bezier(.25, .46, .45, .94) both
}

@-webkit-keyframes tilt-in-fwd-bl {
    0% {
        -webkit-transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
        transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
        opacity: 0
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1
    }
}

@keyframes tilt-in-fwd-bl {
    0% {
        -webkit-transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
        transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
        opacity: 0
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1
    }
}

.httpobject-headers-table {}

.httpobject-headers-table * table {
    table-layout: fixed;
}

.httpobject-headers-table * td>span {
    white-space: pre-line;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.httpobject-inputbar {
    display: flex;
    width: 100%;
}

.httpobject-show-varables {
    padding-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.httpobject-show-varables-modal {
    max-width: 50vh;
    max-height: 60vh;
    position: relative;
    overflow: auto;
}

.httpobject-show-varables-modal::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.httpobject-show-varables-modal::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(177, 177, 177);
}

.httpobject-show-varables-modal::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
}

.script-text {
    font-family: Menlo, Monaco, "Courier New", monospace;
    font-weight: normal;
    font-size: 12px;
    font-feature-settings: "liga"0, "calt"0;
}

.script-text-blue {
    color: #0000ff;
}

.script-text-grey {
    color: #939392;
}

.script-text-black {
    color: #000000;
}

.script-title {
    margin-bottom: 0.2rem;
    margin-top: 0.5rem;
    font-size: 15px;
    font-weight: 500;
    color: #2baebf;
}

.script-title-info {
    margin-left: 5px;
    color: #d9d9d9;
    cursor: pointer;
}

.httpobject-production {
    width: 100%;
}

.httpobject-production * .form-group {
    margin-bottom: 0.5rem;
}

.ho-card {
    padding: 1rem;
    border-bottom: 1px solid #e0e0e075;
    background: #f8f9fe;
    margin: 1rem 0;
    box-shadow: 0 0 0.5rem 0 rgba(136, 152, 170, .15);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ho-card>div {
    flex: 6;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-overflow: inherit;
}

.ho-card>div>span {
    max-width: fit-content;

}

.ho-card>.rs-btn-toolbar {
    flex: 1;
}


.ho-name {
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 500;
    /* max-width: 185px;*/

}

.ho-card:hover * .ho-name {}


/* START */

.ho-name-div {
    width: 10em;
    height: 3em;
    position: relative;
}

.ho-name {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 250%;
    /* big enough */
    display: flex;
    justify-content: flex-end;
    /* Center to the end to have a left overflow */
    white-space: nowrap;
}

.ho-name:after {
    content: "";
    width: 2px;
    margin-left: auto;
    /* empty element to push content to the left */
}

.ho-name-div:hover .ho-name {
    width: 100%;
    /* decrease the width to 100%*/
    transition: 1s linear;
    transition-timing-function: linear;
}



/* END */



.ho-badge {
    background-color: #d8f1f4;
    color: #2baebf;
}

.ho-header {
    display: flex;
    justify-content: space-between;
}

.ho-noresult {
    padding: 1rem 0;
    font-weight: 100;
}

.ho-response-div {
    border: 1px solid #dee2e6;
    position: relative;
}

/*
.ho-response-div::after{
    content:"";
    position:absolute;
    top:0;
    left:0;
    display:flex;
    background:red;
 }
 */

.ho-tree-node {
    display: flex;
    align-items: center;
}

.ho-tree-node>p {
    margin: 0px;
    font-size: 14px;
}

.ho-tree-node>span {
    font-size: 10px;
    font-weight: 900;
    margin-left: 4px;
}

.custom-property-tab * a {
    padding: 0.4rem 1rem !important;
}

.custom-property-tab>.nav-item:not(:last-child) {
    padding-right: .4rem !important;
    border-right: 1px solid #e8e8e8;
}

.custom-property-tab>.nav-item {
    align-items: center;
    justify-content: center;
    display: flex;

}


.custom-property-tab .nav-link {
    color: #cccccc;
    background-color: transparent;
    box-shadow: none;

    transition: all 1s;
}

.custom-property-tab .nav-link.active {
    color: #5d5d5d;
    background-color: transparent;
    box-shadow: none;

}

.hr-margin-bottom {
    margin: 0px !important;
    margin-bottom: 1rem !important;
}

.hr-margin-top {
    margin: 0px !important;
    margin-top: 1rem !important;
}

.func-item:hover {
    text-decoration: underline;
}

.dv-main {
    display: flex;
    flex-direction: row;
}

.dv-field {
    flex: 1;
    margin-right: 10px;
}

.dv-field-title {
    font-size: 14px;
    margin-bottom: 0.5rem;
}

.dv-field-setting {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.dv-dropdown {
    box-shadow: none !important;
    background: #f7f7fa !important;
    border-color: #f2f2f2 !important;
    padding: 5px .7em;
}

.dv-dropdown:hover {
    background: #f2f2f5 !important;
}

.dv-dropdown:active {
    background: #f2f2f5 !important;
    border-color: #e8e8eb !important;

}

.dv-dropdown::after {
    content: none;
}


.custom-drag-comp {
    height: 1px !important;
    width: 100% !important;
    background-color: red !important;
}

.header-more-dropdown-item>.rs-dropdown-item-content {
    color: #8999ab !important;
}

.header-more-dropdown-item>.rs-dropdown-item-content>i {
    margin-right: 10px;
    color: #2baebf;
}

.export-modal-loading {

    top: 0;
    left: 0;
    align-items: center;
    display: flex;


    width: 100%;
    height: 100%;
    /* position: absolute; */
    -webkit-animation: slide-in-fwd-center .4s cubic-bezier(.25, .46, .45, .94) both;
    animation: slide-in-fwd-center .4s cubic-bezier(.25, .46, .45, .94) both
}

.rs-picker-menu {
    z-index: 1050 !important;
}

.export-tab-subtext {
    color: #c8c8ca;
    margin-left: 5px;
    font-size: 14px;
}

.export-tab-subtext:hover {
    text-decoration: underline;
    color: #9daab9;
    cursor: pointer;
}

.btn-export-update {
    background-color: #d8f1f4 !important;
    color: #2baebf !important;
}

.header-project-null-img {
    width: 30px;
    height: 30px;
    border: 2px dashed #2baebf;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 31px;
    font-weight: 100;
    color: #2baebf;
}

.header-project-img {
    width: 30px;
    height: 30px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #9aa7b76b;

}

.clickable-nav>.project-container1>.header-project-img:hover {
    cursor: pointer;
}

.clickable-nav>.project-container1>.header-project-null-img:hover {
    cursor: pointer;
}

.clickable-nav .project-container1:active>.header-project-null-img:hover {
    -webkit-animation: heartbeat 1.5s ease-in-out both;
    animation: heartbeat 1.5s ease-in-out both
}

.clickable-nav .project-container1:active>.header-project-img:hover {
    -webkit-animation: heartbeat 1.5s ease-in-out both;
    animation: heartbeat 1.5s ease-in-out both
}

.flexbox-fix>div>span {
    position: relative;
}

.flexbox-fix>div>span::before {
    content: "";
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==) left center;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 0;
    left: 0;
}

#tooltipvalue {
    position: relative;
}

#tooltipvalue::before {
    /*  content: ""; */
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==) left center;
    width: 36px;
    height: 14px;
    position: absolute;
    top: 5px;
    left: 5px;
    border: 1px solid #e5e5e5;
}

#tooltipcheckbox-label-font-color {
    position: relative;
}

#tooltipcheckbox-label-font-color::before {
    /*  content: ""; */
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==) left center;
    width: 36px;
    height: 14px;
    position: absolute;
    top: 5px;
    left: 5px;
    border: 1px solid #e5e5e5;
}

#tooltipbackground-color {
    position: relative;
}

#tooltipbackground-color::before {
    /*  content: ""; */
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==) left center;
    width: 36px;
    height: 14px;
    position: absolute;
    top: 5px;
    left: 5px;
    border: 1px solid #e5e5e5;
}

#tooltipborder-color {
    position: relative;
}

#tooltipborder-color::before {
    /*  content: ""; */
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==) left center;
    width: 36px;
    height: 14px;
    position: absolute;
    top: 5px;
    left: 5px;
    border: 1px solid #e5e5e5;
}

.global-props-list-item-container::before {
    /*  content: ""; */
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==) left center;
    width: 100%;
    height: 100%;
    position: absolute;


}

.swiper-pages-ul>li {
    color: #8f9192;
}

.swiper-pages-ul>li::marker {
    font-size: 150%;
    color: #91d4df;
}

.swiper-pages-ul-not {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #bbbbbb;
    height: 100%;
    width: 100%;
    display: flex;
}

input:-internal-autofill-selected {
    appearance: menulist-button;
    background-color: auto !important;
    background-image: none !important;
    color: auto !important;
}

.property-dropdown-long{
    width: 100%;
}
.property-dropdown-long>a{
    text-align: start;
    width: 100%;
}
.property-datepicker-long{
    width: 100%;
}
.progressValue{
    margin-bottom:0px !important
}
.rs-loader-spin::after{
    border-color: #2baebf transparent transparent !important;
}